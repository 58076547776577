import { TranslatableField, transl8 } from './TranslatableField';
import { BillingInfoDto } from './BillingInfoDto';
import type { TaxRules } from '.';

export enum TransactionType {
  Charge = 'charge',
  Refund = 'refund',
}

export enum TransactionStatus {
  Concluded = 'concluded',
  Pending = 'pending',
  Canceled = 'canceled',
}

export enum PaymentMethod {
  Invoicing = 'invoicing',
  Paysafe = 'paysafe',
  Stripe = 'stripe',
  TouchNet = 'touchnet',
  Moneris = 'moneris',
  Void = 'void',
  Publisoft = 'publisoft',
  Alipay = 'alipay',
  PayPal = 'paypal',
  PayPalCardFields = 'paypal-cardfields',
  PayPalButtons = 'paypal-buttons',
  GlobalPayments = 'globalpayments',
}

export interface TransactionItemDto {
  name: TranslatableField;
  itemId: string;
  total: number;
  subTotal: number;
  discountedSubTotal: number;
  qst: number;
  gst: number;
  hst: number;
  vat: number;
  pst: number;
  taxRule?: TaxRules;
}

export interface TransactionDto {
  id: string;
  paymentGatewayId: string;
  type: TransactionType;
  nameOnCard: string;
  cardType: string;
  last4Digits: string;
  subTotal: number;
  total: number;
  profit: number;
  refunded: number;
  gst: number;
  qst: number;
  hst: number;
  vat: number;
  pst: number;
  currency: string;
  userFirstName: string;
  userLastName: string;
  paidByFirstName: string;
  paidByLastName: string;
  status: TransactionStatus;
  paymentMethod: PaymentMethod;
  paymentGatewayFee: number;
  fee: number;
  feeGst: number;
  feeHst: number;
  feeQst: number;
  creationDate: string;
  lastUpdateDate: string;
  eventId: string;
  eventName: TranslatableField;
  items: TransactionItemDto[];
  billingInfo?: BillingInfoDto;
  couponCode?: string;
  couponCodeAmount?: number;
  couponCodeAmountType?: string;
}

export function generateUserFriendlyId(id: string): string {
  if (id == null) {
    return '---';
  }
  return id.split('-').slice(0, 2).join('-').toUpperCase();
}

export function generateItemsAsString(items: TransactionItemDto[], currency: string): string {
  if (items == null) {
    return '---';
  }
  return items.map(item => `${transl8(item.name)} (${formatPennies(item.total, currency)})`).join(' + ');
}

export function formatPennies(amount: number, currency: string): string {
  return removeCurrencySpec(
    new Intl.NumberFormat(currency === 'PLN' ? 'pl' : undefined, {
      style: 'currency',
      currency,
    }).format(amount / 100),
  );
}

export function formatMoney(amount: number, currency: string): string {
  return removeCurrencySpec(
    new Intl.NumberFormat(currency === 'PLN' ? 'pl' : undefined, {
      style: 'currency',
      currency,
    }).format(amount),
  );
}

function removeCurrencySpec(amount: string) {
  return amount
    .replace('CA', '') // CAD
    .replace('A', '') // AUD
    .replace('R', '') // BRL
    .replace('MX', '') // MXN
    .replace('US', ''); // USD
}
