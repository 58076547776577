export default {
  page: {
    title: {
      401: 'Access Restricted to Connected Users',
      403: 'Access Restricted to Participants',
      404: 'Page not found',
      default: 'An error occured',
    },
    subtitle: {
      401: 'Please log in to view this content.',
      403: 'Please register to this event to view this content.',
      404: 'It seems like this page does not exist anymore.',
      default: 'Hmm... Something went wrong.',
    },
    cta: {
      401: 'Log in',
      403: 'Register',
      404: 'Go back to the homepage',
      default: 'Go back to the homepage',
    },
  },
  server_validation: {
    required: 'This field is required.',
    compare: 'This field must be identical.',
    not_found: 'This entity cannot be found.',
    already_exist: 'This entity already exist.',
    max_length: 'You entered too many characters. Please shorten the input.',
    email: 'Your email address must be valid.',
    authorization_error: 'You do not have permission to perform this action.',
    user_already_event_participant: 'The user is already a participant of this event.',
    user_already_on_waitlist: "You're already on the waitlist.",
    user_creation: 'An error occured during participant creation.',
    upload_failed: 'An error occurred while loading the file.',
    invalid_file_type: 'Invalid file format.',
    missing_presenter: 'You must have at least one author who is a presenter.',
    invalid_author: 'At least one author has missing information.',
    duplicate_author_email: 'Author emails must be unique.',
    required_maxQuantity: 'The form cannot be completed: all options of a required field are unavailable.',
    user_already_event_submitter: 'A submission already exists for this email address.',
    user_must_be_registered: 'The user is not registered to the event.',
    registration_unavailable: 'The event does not accept new registrations.',
    submission_unavailable: 'The event does not accept new submissions.',
    field_choice_quantity: 'This option is not in sufficient quantity to be selected.',
    field_choice_late_cost:
      'The price of some items has changed since the page loaded. Please validate the amounts before continuing.',
    missing_billinginfo: 'You must enter your billing information.',
    missing_payment_method: 'No payment method has been configured for this event.',
    login_invalid: 'Invalid log in attempt.',
    field_choice_date: 'This option is not available.',
    field_not_found: 'A field not associated with this form has been submitted.',
    session_full: 'Sorry, this call is currently full. Please try again later.',
    payment_error: 'There was an error with your card.',
    payment_maintenance: 'The TouchNet payment system is currently undergoing maintenance. Please try again later.',
    payment_requires_action: 'Action is required on your part in order to complete the payment.',
    payment_auth_error: 'Payment method authentication failed.',
    coupon_code_invalid: 'This coupon code is invalid.',
    stripe: {
      expired_card: 'The card has expired. Check the expiration date or use a different card.',
      incorrect_address: 'The card’s address is incorrect. Check the card’s address or use a different card.',
      incorrect_cvc: 'The card’s security code is incorrect. Check the card’s security code or use a different card.',
      incorrect_number: 'The card number is incorrect. Check the card’s number or use a different card.',
      incorrect_zip: 'The card’s postal code is incorrect. Check the card’s postal code or use a different card.',
      invalid_characters: 'The card number contains characters that are unsupported.',
      invalid_cvc: 'The card’s security code is invalid. Check the card’s security code or use a different card.',
      invalid_expiry_month:
        'The card’s expiration month is incorrect. Check the expiration date or use a different card.',
      invalid_expiry_year:
        'The card’s expiration year is incorrect. Check the expiration date or use a different card.',
      invalid_number: 'The card number is invalid. Check the card details or use a different card.',
      postal_code_invalid: 'The postal code provided was incorrect.',
      processing_error:
        'An error occurred while processing the card. Try again later or with a different payment method.',
      taxes_calculation_failed: 'Tax calculation for the order failed.',
      card_declined: 'The card has been declined.',
      generic_decline: 'The card has been declined for an unknown reason.',
      card_not_supported: 'The card does not support this type of purchase.\t',
      call_issuer: 'The card has been declined for an unknown reason.\t',
      lost_card: 'The payment has been declined because the card is reported lost.\t',
      fraudulent: 'The card has been declined for an unknown reason.',
      insufficient_funds: 'The card has insufficient funds to complete the purchase.',
      do_not_honor:
        'The card is declined. Please contact your bank to review the transaction and determine why it was declined.',
      invalid_account: 'Please contact your bank to review the transaction and determine why it was declined.',
      transaction_not_allowed: 'The transaction is not allowed. Please contact your card issuer for more information.',
    },
    paysafe: {
      billing_street_length: `The street address cannot be longer than 50 characters. Check the card's street address or use a different card.`,
      billing_city_length: `The city cannot be longer than 40 characters. Check the card's city or use a different card.`,
      billing_zip_length: `The postal code cannot be longer than 10 characters. Check the card's postal code or use a different card.`,
      3009: 'Your request has been declined by the issuing bank.',
      3015: `The bank has requested that you process the transaction manually by calling the card holder's credit card company.`,
      3022: 'The card has been declined due to insufficient funds.',
      3024: 'Your request has been declined because the issuing bank does not permit the transaction for this card.',
      3403: 'You have already processed the maximum number of refunds allowed for this Settlement.',
      3406: 'The Settlement you are attempting to Refund has not been batched yet. There are no settled funds available to Refund.',
      3405: 'The Settlement you are attempting to Refund has expired.',
      3415: 'You cannot cancel this transaction as it is no longer in a pending state.',
      3407: 'The Settlement referred to by the transaction response ID you provided cannot be found.',
    },
    touchnet: {
      9: 'There is an error integrating with the payment gateway (TouchNet.) Please contact the event organizer.',
      1724: 'The card address verification (AVS) failed. The address entered does not match the expected value at the card issuer level.',
      1741: 'The card’s security code is invalid. Check the card’s security code or use a different card.',
      1757: 'The account is temporarily locked out for too many failed authorization attempts. Please wait until the account lock is lifted or use a different form of payment.',
      1758: 'The card has been declined for an unknown reason. Please try again later. (1758)',
      1761: 'The card has expired. Check the expiration date or use a different card.',
      1766: 'The amount of the charge exceeds the withdrawal cap set by the card-issuing bank.',
      1777: 'Payment authorization failure. Please contact your card issuer for further details.',
      1923: 'The card has been declined for an unknown reason. Please contact the event organizer. (1923)',
      1924: 'The card has been declined for an unknown reason. Please try again later. (1924)',
      1967: 'The card has been declined for an unknown reason. Please try again later. (1967)',
      1968: 'The card has been declined for an unknown reason. Please try again later. (1968)',
      2900: 'The amount is greater than the amount available for refund.',
    },
    globalpayments: {
      invalid_exp_month: 'The card’s expiration month is incorrect. Check the expiration date or use a different card.',
      invalid_card_number: 'The card’s number is incorrect. Check the card number or use a different card.',
      invalid_cvv: 'The card’s security code is incorrect. Check the card’s security code or use a different card.',
      missing_exp_year: 'The card’s expiration year is missing.',
      missing_exp_month: 'The card’s expiration month is missing.',
      missing_card: 'The card’s number is missing.',
      missing_cvv: 'The card’s security code is missing.',
      incorrect_cvc: 'The card’s security code is incorrect. Check the card’s security code or use a different card.',
      40085: 'The card is invalid. Check the card’s informations or use a different card.',
      declined: {
        101: 'The card has been declined.',
        102: 'The card has been declined.',
        103: 'The card has been declined.',
        108: 'The card has been declined by the bank.',
        111: 'The card has been declined. Authentication Required.',
      },
      50013: 'eCom error. Please try again later. (50013)',
    },
    moneris: {
      tokenization: {
        940: 'Invalid request (940). Please contact the event organizer.',
        941: 'Tokenization error (941). Please contact the event organizer.',
        942: 'Invalid request (942). Please contact the event organizer.',
        943: 'Invalid card’s number.',
        944: 'Invalid card’s expiration date (MM/YY).',
        945: 'Invalid card’s security code.',
        tokenization_error: 'Tokenization error. Please contact the event organizer.',
      },
      '050': 'The card has been declined.',
      '051': 'The card has expired.',
      '052': 'Card not supported.',
      '053': 'The card has been declined for an unknown reason. Please try again later. (053)',
      '054': 'The card has been declined for an unknown reason. Please try again later. (054)',
      '055': 'Card not supported.',
      '056': 'Card not supported.',
      475: 'The card’s expiration is invalid.',
      476: 'The card has been declined.',
      477: 'The card’s number is invalid.',
      481: 'The card has been declined.',
      482: 'The card has expired. Check the expiration date or use a different card.',
      483: 'The card has expired. Check the expiration date or use a different card.',
      484: 'The card has expired. Check the expiration date or use a different card.',
      485: 'Payment authorization failure. Please contact your card issuer for further details.',
      486: 'The card’s security code is invalid.',
      487: 'The card’s security code is invalid.',
      489: 'The card’s security code is invalid.',
      490: 'The card’s security code is invalid.',
      email_length_exceeded: 'Email address exceeds the maximum character limit of 60 characters.',
    },
    paypal: {
      invalid_name: 'The card name is invalid.',
      invalid_number: 'The card number is incorrect.',
      invalid_cvv: 'The card’s security code is invalid.',
      invalid_expiry: 'The card’s expiration is invalid.',
      processing_error: 'An error occurred while processing the payment.',
      order_error: 'An error occurred while creating the order.',
      '0500': 'The card has been declined.',
      9500: 'Try using another card. Do not retry the same card. (Suspected fraud)',
      5400: 'The card has expired.',
      5180: 'Try using another card. Do not retry the same card. (Restricted)',
      5120: 'The card has insufficient funds to complete the purchase.',
      9520: 'Try using another card. Do not retry the same card. (Lost/Stolen)',
      1330: 'The card has been declined.',
      5100: 'The card has been declined.',
      5110: 'The card CVC check failed.',
      '00N7': 'The card CVC check failed.',
    },
    unspecified_error: 'An error has occurred.',
    min: 'The field must be greater.',
    max: 'The field must be less.',
    presentations_exceed_session_time: 'Presentation times are exceeding the schedule duration',
    invalid_password: 'This password is invalid.',
    invalidusername: 'This email is invalid.',
    duplicateusername: 'This email is already taken. Please sign in.',
    duplicate_email: 'This email is already taken.',
    invalid_url_segment: 'This field must contain alphanumeric characters.',
    url_segment_duplicate: 'This value is already used.',
    date_after: 'This field must be after the previous one.',
    user_existing_permissions: 'User is already associated with this role.',
    invalid_on_seed: 'Action not available for seed data.',
    late_cost_need_amount_and_date: 'Amount and date are mandatory when setting a late cost.',
    max_words_not_allowed: "Words limit can't be applied to this field type.",
    field_not_cloneable: 'This field is not cloneable.',
    registration_date_after_event_enddate: `The registration end date cannot be after the event's end date.\n({date})`,
    submission_date_after_event_enddate: `The submission end date cannot be after the event's end date.\n({date})`,
    date_after_event_enddate: `The session cannot be after the event's end date.\n({date})`,
    date_blocked: 'It is no longer possible to modify this date once the event has been published',
    invalid_token: 'Invalid token.',
    invalid_zoom_meeting_id: 'Invalid meeting id',
    email_confirmed: 'Your account is already activated, please log in.',
    too_many_attempts: 'Too many login attempts, please try again in 2 minutes.',
    date_greater_than_today: "The date must be greater than or equal to today's date.",
    vps_disabled: 'The virtual poster session feature is not enabled',
    vps_quota_exceeded: 'You have exceeded the number of hours dedicated to the virtual poster session.',
    url: 'This field must be a valid link (ex: https://fourwaves.com)',
    session_overlap: 'Another session is using this room at the time specified',
    license_not_found: 'This license cannot be found.',
    license_already_used: 'This license has already been used.',
    sequential_id_already_exist: 'This ID already exist',
    invalid_submission_decision: 'This submission decision is invalid',
    maxqty_must_be_greater_than_zero: 'This field must be greater than zero.',
    not_on_waitlist: 'The participant is already removed from waiting list.',
    code_already_used: 'This gateway code has already been used.',
    code_not_found: 'This code cannot be found.',
    alphanumeric: 'Letters and digits only.',
    content_invalid: 'Content invalid (client/server error)',
    license_organization_not_valid_with_event: 'This code is associated with another organization.',
    license_inactive: 'This code is associated with an inactive license.',
    not_refundable: 'It is not possible to refund this transaction.',
    max_file_size: 'Upload failed: file size too large ({size})',
    invalid_file_extension: 'Upload failed: invalid file type ({extension})',
  },
};
